import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Image from "@components/common/CloudinaryImage";
import { Link } from "gatsby";
// import SmallBanner from "@components/common/SmallBanner";
import data from "../../data/Oposiciones";

const Component = () => {
    return (
        <Layout>
            <Seo
                title="Consigue tu plaza de Policía en tiempo récord"
                description="Toda nuestra formación y experiencia a tu servicio para que
                    consigas tu sueño de ser policía en menos de un año. Hemos
                    sido opositores como tú y hemos desarrollado el método
                    perfecto. Con nuestras aplicaciones, libros, podcast, cursos
                    online y formación presencial serás policía en tiempo
                    récord."
            />

            <div className="container lg:px-0 pt-10 pb-20">
                <Title>Consigue tu plaza de Policía en tiempo récord</Title>
                <Text className="text-justify">
                    Toda nuestra formación y experiencia a tu servicio para que consigas tu sueño de
                    ser policía en menos de un año. Hemos sido opositores como tú y hemos
                    desarrollado el método perfecto. Con nuestras aplicaciones, libros, podcast,
                    cursos online y formación presencial serás policía en tiempo récord.
                </Text>
                <div className="py-10 grid grid-cols-1 gap-5">
                    {data
                        ?.filter(element => element.enabled)
                        .map(element => (
                            <Element {...element} key={element.id} />
                        ))}
                </div>
            </div>
        </Layout>
    );
};

const Element = ({
    id,
    image,
    imageAlt = "",
    background,
    backgroundAlt = "",
    title,
    description,
    button,
    href,
}) => {
    const [hover, setHover] = React.useState(false);

    // Obtenmos el primer item del array
    const firstHref = href[0];

    return (
        <Link to={firstHref} title={title}>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                role="button"
                tabIndex={id}
            >
                <div className={styles.element.container}>
                    {/** Imagen Background */}
                    <div className={styles.element.imageContainer}>
                        <Image
                            filename={background}
                            alt={backgroundAlt}
                            className={`${styles.element.image}`}
                        />
                    </div>

                    {/** Imagen Foreground */}
                    <div className={styles.element.imageForegroundContainer}>
                        <Image
                            alt={imageAlt}
                            filename={image}
                            className={styles.element.imageForeground}
                        />
                    </div>

                    {/** Background Gradient */}
                    <div
                        className={`${styles.element.backgroundOverlay} ${
                            hover ? "bg-opacity-25 opacity-25" : "bg-opacity-50 opacity-50"
                        }`}
                    />

                    {/** Texts */}
                    <div className={styles.element.textContainer}>
                        <div className="space-y-5">
                            <h1 className={styles.element.title}>{title}</h1>

                            <h2 className={styles.element.description}>{description}</h2>

                            <h3 className={styles.element.button}>{button}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

const styles = {
    element: {
        container: "bg-gray-200 h-96 xl:h-116 rounded-xl shadow relative",
        backgroundOverlay:
            "absolute z-30 w-full h-full top-0 left-0 bg-black rounded-xl bg-gradient-to-b from-transparent to-gray-black duration-300",
        imageContainer: "absolute z-0 w-full h-full rounded-xl overflow-hidden",
        image: "w-full h-full object-cover",
        textContainer: "absolute w-full bottom-0 left-0 p-5 md:p-10 z-50",
        title: "font-montserrat font-medium text-2xl lg:text-3xl text-white",
        description: "font-montserrat text-white lg:text-lg",
        button: "bg-black bg-opacity-50 rounded-xl py-2 px-5 font-medium text-white font-poppins inline-block lg:text-base text-sm",
        imageForegroundContainer: "absolute bottom-0 right-0 h-full w-full lg:w-2/3",
        imageForeground: "w-full h-full object-cover rounded-br-xl",
    },
};

export default Component;
